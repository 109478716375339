.avatar-md {
  text-align: center;
  font-size: 1.5rem;
  line-height: 4rem;
}

.bg-green {
  background-color: #34c38f !important;
}

.table thead tr {
  background: #eff2ff !important;
}

.sidebar-enable img {
  display: none !important;
}

.css-1nmdiq5-menu > div {
  background-color: #fff !important;
}

.pagination {
  .page-item {
    .page-link {
      border-radius: 0;
    }

    &:first-child {
      .page-link {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    }

    &:last-child {
      .page-link {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }
}